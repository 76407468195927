<template>
<div id="banner-container" class="banner-container">
    <div class="flex">
        <i class="fa-solid fa-flower-daffodil seasonal-icon" />
        <p class="sale-text">{{ $t('SALE_BANNER.BANNER_TEXT') }}</p>
        <NuxtLink :to="navigateToCtaRoute" class="homepage-banner">
            <div role="button" tab-index="0" class="homepage-banner cta-btn" target="_blank">
                {{ $t('SALE_BANNER.BUTTON_TEXT') }}
            </div>
        </NuxtLink>
        <div
            role="button" tabIndex="0" aria-label="close sale banner"
            v-on:click="close"
            v-on:keydown.enter="close"
        >
            <i class="fa-solid fa-xmark close" />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { RouteLocation } from 'vue-router'

export default defineComponent({
    emits: ['close'],

    computed: {
        navigateToCtaRoute(): RouteLocation {
            return this.localeRoute({
                name: 'sale'
            })
        }
    },

    mounted() {},

    beforeUnmount() {},

    methods: {
        close() {
            this.setSaleBannerTimeoutCookie()
            this.$emit('close')
        },

        setSaleBannerTimeoutCookie() {
            // don't show the banner again for a week
            const expDate = new Date()
            expDate.setDate(expDate.getDate() + 7)
            document.cookie = `name=sale_banner_timeout; expires=${expDate.toUTCString()}; Secure`
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.banner-container {
    animation: slide-up 0.7s linear;
    background: var(--gradient2, linear-gradient(90deg, #844CED 0%, #DE08C0 52.6%, #DF8A0C 100%));
    padding: 25px;
    position: sticky;
    bottom: 0px;
    /*ensures this will be on top of the zendesk chat button, which has a z-index of 999999*/
    z-index: 1000000;

    @keyframes slide-up {
        0% {transform: translateY(500px);}
        100% {transform: translateY(0px);}
    };

    @include mq-xsmall {
        background: linear-gradient(180deg, #844CED 0%, #DE08C0 50%, #DF8A0C 100%);
        border-radius: 32px 32px 0px 0px;
        padding: 25px 25px 0px 25px;
    }

    .flex {
        align-items: center;
        display: flex;
        flex-direction: row;

        @include mq-xsmall {
            flex-direction: column;
            gap: 16px;
        }

        .close {
            width: 34px;
            height: 34px;

            :hover {
                cursor: pointer;
            }

            @include mq-xsmall {
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }

        .cta-btn {
            align-items: center;
            background-color: var(--primary-50);
            border-radius: 4px;
            color: var(--neutral-900);
            display: flex;
            font-weight: 600;
            font-size: 16px;
            height: 48px;
            justify-content: center;
            margin-right: 20px;
            padding: 8px 16px;
            width: 200px;

            &:hover {
                cursor: pointer;
                background-color: var(--neutral-900);
                color: var(--primary-50);
            }
        }

        .sale-text {
            font-size: 20px;
            font-weight: 600;
            line-height: 22px;
            margin: auto 0;

            @include mq-xsmall {
                line-height: 24px;
                text-align: center;
                max-width: 380px;
            }
        }

        .seasonal-icon {
            height: 40px;
            width: 40px;
            margin-right: 24px;

            @include mq-xsmall {
                margin-top: 12px;
                margin-right: 0px;
            }
        }
    }

    .flex :nth-last-child(2){
        margin-left:auto;

        @include mq-xsmall {
            margin-left: 0px;
        }
    }
}
</style>
